import { ContactShape } from "entities/contact/types";
import { selectContractMainLang } from "redux/auth/selectors";
import { useAppSelector } from "redux/hooks";
import { i18n } from "utilities";

export const DEFAULT_SALUTATION_LANGUAGE = "de" as const;

type Gender = "0" | "1" | "2";
type Salutation = Record<Gender, string>;
type Salutations = Record<string, Salutation>;

// NOTE: salutations are hardcoded here by purpose, until we implement a way to fetch only specific translation
// case "special_0" is for purpose of this task: https://casasoftag.atlassian.net/browse/CASAONE-2282
const salutations: Salutations = {
  en: {
    "0": "To whom it may concern,",
    "1": "Dear Mr. %s,",
    "2": "Dear Ms. %s,",
  },
  de: {
    "0": "Sehr geehrte Damen und Herren",
    "1": "Sehr geehrter Herr %s",
    "2": "Sehr geehrte Frau %s",
  },
  fr: {
    "0": "Bonjour,",
    "1": "Monsieur %s,",
    "2": "Madame %s,",
  },
  it: {
    "0": "Buongiorno,",
    "1": "Egregio signor %s,",
    "2": "Gentile signora %s,",
  },
  es: {
    "0": "A quien le interese",
    "1": "Estimado Sr. %s",
    "2": "Estimada Sra. %s",
  },
} as const;

export const useSalutation = () => {
  const contractMainLanguage = useAppSelector(selectContractMainLang);

  const getSalutation = (language?: string, gender: Gender = "0"): string => {
    const availableLangs = Object.keys(salutations);

    if (language && availableLangs.includes(language)) {
      return salutations[language][gender];
    } else if (availableLangs.includes(i18n.language)) {
      return salutations[i18n.language][gender];
    } else if (
      contractMainLanguage &&
      availableLangs.includes(contractMainLanguage)
    ) {
      return salutations[contractMainLanguage][gender];
    } else {
      return salutations[DEFAULT_SALUTATION_LANGUAGE][gender];
    }
  };

  const renderSalutation = (
    data: {
      firstName?: ContactShape["firstName"];
      lastName?: ContactShape["lastName"];
      lang?: ContactShape["lang"];
      gender?: ContactShape["gender"];
      salutationTemplate?: ContactShape["salutationTemplate"];
    },
    ignoreSalutationTemplate: boolean = false
  ): string => {
    const language =
      data.lang || contractMainLanguage || DEFAULT_SALUTATION_LANGUAGE;
    const template = data.salutationTemplate;

    if (template && !ignoreSalutationTemplate) {
      return template;
    }

    const gender =
      data.gender === null ||
      data.gender === undefined ||
      data.gender === "" ||
      data.gender === "0" ||
      data.gender === 0
        ? "0"
        : data.gender.toString();

    // special case for non gender but fully named people
    if (gender === "0" && data.firstName && data.lastName) {
      switch (language) {
        case "en":
          return "Dear %s,".replace("%s", `${data.firstName} ${data.lastName}`);
        case "fr":
          return "Bonjour,";
        case "it":
          return "Buongiorno,";
        case "es":
          return "Estimado %s".replace(
            "%s",
            `${data.firstName} ${data.lastName}`
          );
        default:
          return "Guten Tag %s".replace(
            "%s",
            `${data.firstName} ${data.lastName}`
          );
      }
    }
    if (data.lastName && (gender === "1" || gender === "2")) {
      return getSalutation(data.lang, gender).replace("%s", data.lastName);
    }
    return getSalutation(data.lang, "0");
  };

  return {
    renderSalutation,
  };
};
