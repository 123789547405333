import { CreatePropertyContentDescriptionRequest } from "./types";
import { createQuery } from "api/createEndpoint";

const query = createQuery<CreatePropertyContentDescriptionRequest>((body) => {
  const { propertyId, ...data } = body;

  return {
    url: "property-content-description",
    method: "POST",
    data,
  };
});

export default query;
