import {
  CreatePropertyContentDescriptionRequest,
  CreatePropertyContentDescriptionResponse,
} from "./types";
import { createInvalidatesTags } from "api/createEndpoint";

const invalidatesTags = createInvalidatesTags<
  CreatePropertyContentDescriptionResponse,
  CreatePropertyContentDescriptionRequest
>((_result, _err, queryParams) => {
  if (queryParams.propertyId) {
    return [{ type: "Property", id: queryParams.propertyId }];
  }
  return ["Property"];
});

export default invalidatesTags;
