import { casaoneApi } from "api";

import iaziCommonDataRequestQuery from "./endpoints/get-common-data/query";
import iaziTextStylesRequestQuery from "./endpoints/get-text-styles/query";
import iaziGenerateTextRequestQuery from "./endpoints/post-generate-text/query";
import iaziCommonDataRequestProvidesTags from "./endpoints/get-common-data/providesTags";
import transformResponse from "./endpoints/get-common-data/transformResponse";

const iaziApi = casaoneApi.injectEndpoints({
  endpoints: (builder) => ({
    iaziGetCommonData: builder.query({
      query: iaziCommonDataRequestQuery,
      providesTags: iaziCommonDataRequestProvidesTags,
      transformResponse: transformResponse,
    }),
    iaziGetTextStyles: builder.query({
      query: iaziTextStylesRequestQuery,
      providesTags: ["IaziTextStyles"],
    }),
    iaziGenerateText: builder.mutation({
      query: iaziGenerateTextRequestQuery,
    }),
  }),
  overrideExisting: false,
});

export const {
  useIaziGetCommonDataQuery,
  useLazyIaziGetCommonDataQuery,
  useIaziGetTextStylesQuery,
  useLazyIaziGetTextStylesQuery,
  useIaziGenerateTextMutation,
} = iaziApi;
