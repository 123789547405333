import { useTranslation } from "react-i18next";
import { type AlertItem } from "./models";

const ACTIVE_FROM = new Date("2024-11-26T00:00:00");
const ACTIVE_TO = new Date("2024-11-26T23:59:59");

export const useMaintenanceWarning = () => {
  const { t } = useTranslation();

  const now = new Date();

  const maintenanceWarning: AlertItem | undefined =
    ACTIVE_FROM < now && now < ACTIVE_TO
      ? {
          colorType: "info",
          cookieId: "cs_maintenance_2024-06-17",
          text: `${t(
            "On Friday, November 22, we will be unavailable by phone from 08{{dp}}30 to 09{{dp}}30 due to a phone infrastructure update. Please send your requests by email to {{supportEmail}} during this time.",
            {
              supportEmail: "support@casasoft.ch",
              dp: ":",
            }
          )}`,
        }
      : undefined;

  return {
    maintenanceWarning,
  };
};
