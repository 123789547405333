import { CasaoneBaseQueryArgs } from "api/types";
import { IaziGenerateTextRequestBody } from "./types";

const query = (body: IaziGenerateTextRequestBody): CasaoneBaseQueryArgs => ({
  url: `api/v1/iazi-ai-text-generator`,
  method: "POST",
  data: { ...body },
});

export default query;
