import * as RadixTooltip from "@radix-ui/react-tooltip";
import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export interface TooltipProps extends RadixTooltip.TooltipProps {
  children: ReactNode;
  content?: ReactNode;
  placement?: "top" | "right" | "bottom" | "left";
  textAlign?: "center" | "left" | "right";
  /** Use this when you want to provide a button as the child of the tooltip. This will pass down the trigger props to the child button instead of nesting two buttons (which is invalid html) */
  triggerAsChild?: boolean;
}

function Tooltip({
  children,
  content,
  textAlign,
  placement = "bottom",
  delayDuration = 0,
  triggerAsChild,
  ...tooltipProps
}: TooltipProps) {
  // only render tooltip if it has content
  if (!content) {
    return <>{children}</>;
  }
  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root {...tooltipProps} delayDuration={delayDuration}>
        <RadixTooltip.Trigger asChild={triggerAsChild}>
          {children}
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            arrowPadding={8}
            collisionPadding={5}
            side={placement}
            className={twMerge(
              "tw-bg-cs-shade-black tw-z-50 tw-text-cs-shade-white tw-rounded-md tw-py-1 tw-px-2 tw-text-sm tw-max-w-md tw-pointer-events-auto"
            )}
            style={{ textAlign: textAlign }}
          >
            <RadixTooltip.Arrow />
            {content}
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
}

export default Tooltip;
