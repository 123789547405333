import { createQuery } from "api/createEndpoint";

const query = createQuery<void>(() => {
  return {
    url: `api/v1/iazi-ai-text-styles`,
    method: "GET",
  };
});

export default query;
